<template>
  <v-container fluid>
    <div v-if="legal.listOfLegalExcetpions.length > 0">
      <v-list
        v-for="(exceptions, index) in legal.listOfLegalExcetpions"
        :key="index"
      >
        <v-card flat class="my-2">
          <v-row>
            <v-col cols="2">
              <div class="text-h7 font-weight-bold ml-2">{{exceptions.userName}}</div>
            </v-col>
            <v-col cols="2">
              <div class="text-h7 font-weight-light" v-if="legal.listOfLegalExcetpions.length>0">
                {{ new Date(exceptions.dateOfComment).toLocaleTimeString() }} |
                {{ new Date(exceptions.dateOfComment).toLocaleDateString() }}
              </div>
            </v-col>
          </v-row>
          <v-row align="center">
            <span class="mx-4">
              {{ exceptions.comments }}
            </span>
          </v-row>
          <v-row>
            <v-col>
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </v-card>
      </v-list>
    </div>
    <!-- <div v-else>
      <v-card>
        no exceptions
      </v-card>
    </div> -->
    <v-row>
      <v-col cols="12">
        <v-form ref="adminCommentFrom">
          <v-text-field
          v-if="showAddButton"
            label="Add Exceptionss"
            v-model="adminComments.comments"
            outlined
            solo
            v-on:keyup.enter="createAnLegalExcpetion"
          ></v-text-field>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
       <v-btn v-if="showAddButton" @click="createAnLegalExcpetion"  class="mx-3" color="primary" style="background-color:blue" >Post Exception</v-btn>
    </v-row>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "ExceptionsInLegal",
  data() {
    return {};
  },
  computed: {
    ...mapState("trainerProfile", {
      loading: "loading",
      legal: "legal",
      adminComments: "adminComments",
      profile: "profile"
    })
  },
  props: ["type" ,"showAddButton"],
  methods: {
    createAnLegalExcpetion() {
      if (this.adminComments.comments) {
        this.adminComments.type = this.type;
        this.adminComments.trainerId = this.profile.trainerId;
        console.log("admin commnetst------------", this.adminComments);
        this.$store.dispatch(
          "trainerProfile/createAdminCommnets",
          this.adminComments
        );
        this.$refs.adminCommentFrom.reset();
      }
    }
  }
};
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: rgba(38, 123, 250, 0.986);
}
</style>
