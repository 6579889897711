<template>
  <v-container fluid>
    <SecondaryNavigation />
    <v-row>
      <v-col col="11">
        <div class="text-h5 font-weight-bold mx-3">
          Legals
        </div>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <ProfileCard />
    </v-row>
    <div class="text-h6 font-weight-bold  text--primary">
      Stage 3A
    </div>
    <v-row>
      <v-card flat class="my-5" width="1400px">
        <v-layout column>
          <v-row justify="space-between">
            <v-card-title> </v-card-title>
            <v-card-actions>
              <v-btn
                @click="enableEditMode"
                v-if="!editMode.legal"
                class="mx-3"
                color="primary"
                style="background-color:blue"
                >Edit</v-btn
              >
              <v-btn
                v-else-if="!legal.legalId && editMode.legal"
                @click="createLegal"
                class="mx-3"
                color="primary"
                slot="activator"
                style="background-color:blue"
                :loading="loading"
                >Save</v-btn
              >
              <v-btn
                v-else
                @click="updateLegal"
                class="mx-3"
                color="primary"
                slot="activator"
                style="background-color:blue"
                :loading="loading"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-row>
          <v-card-text>
            <v-form v-model="valid" ref="legalForm">
              <v-row>
                <v-col cols="3">
                  <div
                    class="mx-6 my-n11 text-h6 font-weight-bold"
                    v-if="!editMode.legal"
                  >
                    <span class="primary--text">
                      Aggrement status
                    </span>
                  </div>
                  <div v-else class="mx-6 my-n11 text-h6 font-weight-bold">
                    Aggrement status
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="8">
                  <div
                    v-if="!editMode.legal"
                    class="mx-6 my-n6 text-h6 font-weight-bold"
                  >
                    {{ legal.legalStatus }}
                  </div>
                  <v-btn-toggle
                    v-else
                    v-model="legal.legalStatus"
                    mandatory
                    class="my-3"
                  >
                    <v-btn
                      color="primary"
                      outlined
                      rounded
                      class="mx-3"
                      value="Not Started"
                    >
                      Not Started
                    </v-btn>
                    <v-btn
                      color="primary"
                      outlined
                      class="mx-3"
                      rounded
                      value="In-Progress"
                    >
                      In-Progress
                    </v-btn>
                    <v-btn
                      color="primary"
                      class="mx-3"
                      outlined
                      rounded
                      value="Completed"
                    >
                      Completed
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <v-col cols="3">
                  <div
                    class="mx-6 text-h6 font-weight-bold primary--text"
                    v-if="!editMode.legal"
                  >
                    Date of signing agreement
                  </div>
                  <div class="mx-6 text-h6 font-weight-bold" v-else>
                    Date of signing agreement
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4" class="mx-3">
                  <div
                    class="mx-3 text-h6 font-weight-bold my-n6"
                    v-if="!editMode.legal"
                  >
                    {{ legal.dateOfAgrrement }}
                  </div>
                  <v-menu
                    v-else
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        v-model="legal.dateOfAgrrement"
                        label=""
                        placeholder="Select agrrement signining date"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        height="10"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="legal.dateOfAgrrement"
                      @input="menu = false"
                      :max="
                        new Date(
                          Date.now() - new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substr(0, 10)
                      "
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <!-- <v-row>
                <v-col cols="4">
                  <div class="mx-6 text-h6 font-weight-bold">
                    Exceptions to signing aggrement
                  </div>
                </v-col>
              </v-row> -->
              <v-row>
                <v-col cols="4">
                  <div
                    class="mx-6 text-h6 font-weight-bold primary--text"
                    v-if="!editMode.legal"
                  >
                    Uploaded agreement documents
                  </div>
                  <div class="mx-6 text-h6 font-weight-bold" v-else>
                    Upload agreement documents
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-file-input
                    v-if="editMode.legal"
                    outlined
                    dense
                    accept="image/*,.pdf,.doc,.docx"
                    label="File input"
                    @change="tmpUploadFile"
                  ></v-file-input>
                </v-col>
                <v-col>
                  <v-btn
                    v-if="editMode.legal"
                    @click="uploadDocumnet"
                    class="mx-3"
                    color="primary"
                    slot="activator"
                    style="background-color:blue"
                    :loading="loading"
                    >Upload Document</v-btn
                  >
                </v-col>
              </v-row>
              <DeleteAlertBox
                :dialog="deleteAlertBoxSatus"
                @onOk="confirmDelte"
                @onCancel="onCancel"
              />
              <v-row v-if="legal.legalDocumentUrl != '' && editMode.legal">
                <v-col cols="3">
                  <div class="mx-6 text-h6 font-weight-bold black--text">
                    Legal document file
                  </div>
                </v-col>
              </v-row>
              <v-card
                outlined
                flat
                width="450px"
                v-if="legal.legalDocumentUrl != ''"
              >
                <v-row>
                  <v-col cols="11">
                    <div
                      class="text-h7 font-weight-bold mx-2 my-n1 text--primary"
                    >
                      <a @click="getLegalDetailFile">
                        {{ getFileName(legal.legalDocumentUrl) }}
                      </a>
                    </div>
                  </v-col>
                  <v-col cols="1" class="mx-n2 my-n2">
                    <v-icon
                      color="red"
                      @click="
                        deleteLegalDocument('LEGEAL', legal.legalDocumentUrl)
                      "
                      v-if="editMode.legal"
                    >
                      delete
                    </v-icon>
                  </v-col>
                </v-row>
              </v-card>
              <!-- <v-col cols="4" v-if="legal.legalDocumentUrl != ''">
                  <div v-if="editMode.legal" class="text-h7 font-weight-bold mx-3 my-n5">
                    <a @click="getLegalDetailFile">
                      {{ getFileName(legal.legalDocumentUrl) }}
                    </a>
                  </div>
                  <div v-else class="text-h7 font-weight-bold mx-3 my-n9">
                    <a @click="getLegalDetailFile">
                      {{ getFileName(legal.legalDocumentUrl) }}
                    </a>
                  </div>
                </v-col> -->
            </v-form>
          </v-card-text>
        </v-layout>
      </v-card>
    </v-row>
    <v-row>
      <v-col cols="4">
        <div class="mx-6 text-h6 font-weight-bold">
          Exceptions to signing aggrement
        </div>
      </v-col>
    </v-row>
    <ExceptionsInLegal type="LEGAL" :showAddButton="editMode.legal" />
    <a
      class="text-h6 font-weight-bold  blue--text mx-n3"
      @click="routeTo"
      v-if="!editMode.legal"
    >
      Click here to fill Onboarding Documents Collected (formality)
    </a>
    <!-- <v-row>
      <div :class="editMode.legal?activeClass:nonActive">
             Admin comments
    </div>
    <AdminComments :showAddButton="editMode.legal" type="LEGAL_DETAILS" />
    </v-row> -->
  </v-container>
</template>

<script>
import SecondaryNavigation from "../../../components/SecondaryNavigation/SecondaryNavigation.vue";
import ProfileCard from "../../../components/ProfileCard/ProfileCard.vue";
import utils from "@/utils/utilities";
import { mapState } from "vuex";
import DeleteAlertBox from "../../../components/originafilesAlertBox.vue";
//import AdminComments from "../../../components/AdminComments/AdminComments.vue"
import ExceptionsInLegal from "../../../components/exceptionsInLegal.vue";
export default {
  name: "Legal",
  components: {
    SecondaryNavigation,
    ProfileCard,
    ExceptionsInLegal,
    DeleteAlertBox
    // DownloadFileComponent
    //AdminComments
  },
  data() {
    return {
      activeClass: "text-h6 font-weight-bold mx-3",
      nonActive: "text-h6 primary--text font-weight-bold",
      fileToSend: null,
      documentUploadSource: "",
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      toggle_exclusive: undefined,
      valid: false
    };
  },
  computed: {
    ...mapState("trainerProfile", {
      loading: "loading",
      profile: "profile",
      legal: "legal",
      editMode: "editMode",
      legalDocumentSource: "legalDocumentSource",
      deleteAlertBoxSatus: "deleteAlertBoxSatus",
      afterOk: "afterOk"
    })
  },
  created() {
    this.$store.dispatch(
      "trainerProfile/getLegalDetials",
      this.profile.trainerId
    );
  },
  methods: {
    tmpUploadFile(file) {
      console.log("file to upload--", file);
      if (file.size === 0) {
        this.fileToSend = null;
        return;
      }
      let filename = file.name;
      if (!utils.getValidMediaType("DocPrev", filename)) {
        this.documentUploadSource = "";
        this.fileToSend = null;
      } else {
        this.fileToSend = file;
        let URL = window.URL || window.webkitURL;
        console.log("url ----", URL);
        this.documentUploadSource = URL.createObjectURL(this.fileToSend);
        console.log("document upload sourse", this.documentUploadSource);
      }
    },
    getFileName(s3Name) {
      let splitArray = s3Name.split("/");
      splitArray = splitArray[splitArray.length - 1];
      splitArray = splitArray.split("_");
      splitArray = splitArray[splitArray.length - 1];
      return splitArray;
    },
    getLegalDetailFile() {
      this.$store.dispatch("trainerProfile/downloadLegalFile");
    },
    createLegal() {
      console.log("leagal  ---", this.legal);
      this.legal.trainerId = this.profile.trainerId;
      this.$store.dispatch("trainerProfile/createLegalDetiails", this.legal);
    },
    updateLegal() {
      console.log("leagal  ---", this.legal);
      this.$store.dispatch("trainerProfile/updateLegalDetials", this.legal);
    },
    uploadDocumnet() {
      this.$store.dispatch("trainerProfile/uploadLegalFile", {
        data: this.fileToSend,
        trainerId: this.profile.trainerId,
        cb: ""
      });
    },
    deleteLegalDocument(type, fileUrl) {
      console.log("file to be deleted ", type, fileUrl);
      this.$store.dispatch("trainerProfile/deleteAFile", {
        type: type,
        fileUrl: fileUrl,
        deleteConfirmation: false
      });
    },
    confirmDelte() {
      this.$store.dispatch("trainerProfile/deleteAFile", {
        type: this.afterOk.type,
        fileUrl: this.afterOk.fileUrl,
        deleteConfirmation: true
      });
    },
    onCancel() {
      this.$store.dispatch("trainerProfile/closeDeleteDailog");
    },
    routeTo() {
      this.$router.push("/pages/paymentDetails");
    },
    enableEditMode() {
      let editMode = this.$store.dispatch(
        "trainerProfile/changeEditMode",
        "LEGAL"
      );
      console.log("edmit mode return ", editMode);
    }
  }
};
</script>

<style></style>
